import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultPincodeData = {
  id: 0,
  enabled: true,
  countryId: undefined,
  country: {
    id: null
  },
  stateId: undefined,
  state: {
    id: null
  },
  cityId: undefined,
  city: {
    id: null
  },
  pincode: '',
  pincodeName: ''
};
export var getPincodes = function getPincodes(params) {
  return request({
    url: '/pincodes',
    method: 'get',
    params: params
  });
};
export var getPincodeById = function getPincodeById(id) {
  return request({
    url: "/pincodes/".concat(id),
    method: 'get'
  });
};
export var updatePincode = function updatePincode(id, data) {
  return request({
    url: "/pincodes/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deletePincode = function deletePincode(id) {
  return request({
    url: "/pincodes/".concat(id),
    method: 'delete'
  });
};
export var createPincode = function createPincode(data) {
  return request({
    url: '/pincodes/',
    method: 'post',
    data: data
  });
};